<!-- 
	This is the Timeline page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div id="settings">
    <a-tabs class="tabs-sliding" default-active-key="1" style="margin-bottom: 24px;">
      <a-tab-pane key="1" tab="任务参数">
        <a-card class="header-solid" :bodyStyle="{padding: '30px'}">
          <a-descriptions bordered id="basic">
            <a-descriptions-item label="打新平台">
              {{ exchange }}
            </a-descriptions-item>
            <a-descriptions-item label="打新API">
              {{ api_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ create_datetime }}
            </a-descriptions-item>
            <a-descriptions-item label="保证金模式">
              {{ mgnMode }}
            </a-descriptions-item>
            <a-descriptions-item label="投资额">
              {{ investment }}
            </a-descriptions-item>
            <a-descriptions-item label="杠杆设置">
              {{ leverage }}
            </a-descriptions-item>
            <a-descriptions-item v-if="trade_trigger_mode === 1" label="单笔止盈">
              {{ tp_trigger_px == '0'?'未设置':tp_trigger_px+'%' }}
            </a-descriptions-item>>
            <a-descriptions-item v-if="trade_trigger_mode === 1" label="单笔止损">
              {{ sl_trigger_px == '0'?'未设置':sl_trigger_px+'%' }}
            </a-descriptions-item>
            <a-descriptions-item label="首单交易方向">
              {{ first_order_set }}
            </a-descriptions-item>
            <a-descriptions-item v-if="trade_trigger_mode === 1" label="是否反手开单">
              {{ reverse_set === 1?'是':'否' }}
            </a-descriptions-item>
            <a-descriptions-item label="任务状态" :span="3">
              <a-badge v-if="status === 1" status="processing" text="Running" />
              <a-badge v-if="status !== 1" status="error" text="Stop" />
              <a-button v-if="status === 1" type="danger" :size="small" class="stop_btn" @click="stopTask()">终止跟单</a-button>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-tab-pane>

    </a-tabs>
    <a-row type="flex" :gutter="[24,24]">

      <a-col :span="24" :lg="12">

        <!-- Orders History Timeline Card -->
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{paddingTop: '12px',}">
          <template #title>
            <div style="display: flex;  align-items: center;">
              <a-icon type="bug" theme="outlined" class="svg"/>
              <h6 style="display: inline-block; margin-left: 10px">打新监控记录</h6>
            </div>
          </template>
          <a-timeline>
            <a-timeline-item :color="item.color" v-for="item in spiderData" :key="item.date">
              {{ item.title }}
              <small>{{ item.date }}</small>
              <p>
                {{ item.description }}
              </p>
            </a-timeline-item>
          </a-timeline>
        </a-card>
        <!-- / Orders History Timeline Card -->

      </a-col>

      <a-col :span="24" :lg="12">

        <!-- Orders History Timeline Card -->
        <a-card :bordered="false" class="bg-gray-10 header-solid mb-24" :bodyStyle="{paddingTop: '12px',}">
          <template #title>
            <div style="display: flex;  align-items: center;">
              <img src="/images/white-logo.png" style="width: 33px">
              <h6 class="text-white" style="display: inline-block;margin-left: 10px">打新交易记录</h6>
            </div>
          </template>
          <a-timeline class="timeline-dark">
            <a-timeline-item :color="item.color" v-for="item in tradeData" :key="item.date">
            {{ item.title }}
            <small>{{ item.date }}</small>
            <p>
              {{ item.description }}
            </p>
          </a-timeline-item>
          </a-timeline>
        </a-card>
        <!-- / Orders History Timeline Card -->

      </a-col>

    </a-row>

  </div>

</template>

<script>

import http from "@/utils/http";


function transformItem(item){
  if(item.color == 'SUCCESS'){
    item.color ='green';
  }else if(item.color == 'WARNING'){
    item.color = 'danger';
  }else if(item.color == 'INFO'){
    item.color = 'primary';
  }
  return {
    title: item.title,
    date: item.date,
    description: item.description,
    color: item.color,
  };
}

export default {
  components: {
  },
  data() {
    return {
      exchange: '',
      api_name:'',
      create_datetime: '',
      first_order_set: '',
      investment: '',
      leverage: '',
      mgnMode: '',
      reverse_set: '',
      trade_trigger_mode: '',
      sl_trigger_px: '',
      tp_trigger_px: '',
      status: '',

      spiderData: [], // 爬虫数据
      tradeData: [],  // 交易数据
    };
  },

  created() {
    const id = this.$route.params.id;
    if (id) {
      this.loadData(id);
      this.loadStatus(id);
    } else {
      // 处理没有 key 的情况
      this.$router.push("/ico-tasklist");
    }
  },
  methods: {
    transTP() {
      if(this.exchange === 1){
        return 'OKX';
      }else if(this.exchange === 2){
        return 'Binance';
      }
    },
    async loadStatus(id) {
      try {
        const response = await http.get("/api/ico_detial/" + id + "/?token=" + localStorage.getItem("token"));
        this.exchange = response.data.data.exchange;
        this.exchange = this.transTP();

        this.api_name = response.data.data.api_name;
        this.create_datetime = response.data.data.create_datetime;
        this.first_order_set = response.data.data.first_order_set;
        this.investment = response.data.data.investment;
        this.leverage = response.data.data.leverage;
        this.mgnMode = response.data.data.mgnMode;
        this.reverse_set = response.data.data.reverse_set;
        this.trade_trigger_mode = response.data.data.trade_trigger_mode;
        this.sl_trigger_px = response.data.data.sl_trigger_px;
        this.tp_trigger_px = response.data.data.tp_trigger_px;
        this.status = response.data.data.status;
      }catch (error) {
        console.log('更新失败:', error); // 错误处理
      }
    },
    async stopTask() {
      this.$confirm({
        title: '确认终止',
        content: '终止任务不会进行平仓，当前如有持仓，后续请自行平仓。',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            await http.patch("/api/ico/?token=" + localStorage.getItem("token"), {id: this.$route.params.id});
            // 刷新当前页面
            window.location.reload();
          } catch (error) {
            console.error('更新失败:', error); // 错误处理
          }
        },
        onCancel() {
          console.log('终止操作已取消');
        },
      });
    },
    async loadData(id) {
      const url = '/api/traderdetial/'+id+'/?token='+localStorage.getItem("token");
      http.get(url).then(response => {
        if (response.data.code!== 0) {
          this.$message.error(response.data.detail);
          // this.$router.push("/tasklist");
          return;
        }
        this.tradeData = response.data.data.trade.map(item => transformItem(item));
        this.spiderData = response.data.data.spider.map(item => transformItem(item));
      }).catch(error => {
        console.error(error);
      });
    },

  },
};
</script>

<style lang="scss">
.stop_btn {
  margin-left: 20px;
  height: 32px;
  //position: absolute;
  //right: 20px;
  //top: 10px;
  //z-index: 101;
}
.ant-timeline-item-head-danger {
  color: #f5222d;
  border-color: #f5222d;
}

.ant-timeline-item-head-primary {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-timeline-item-head-warning {
  color: #fadb14;
  border-color: #fadb14;
}

.ant-timeline-item-head-secondary {
  color: #b37feb;
  border-color: #b37feb;
}

.ant-timeline-item-head-dark {
  color: #141414;
  border-color: #141414;
}

.ant-timeline-item-head-success {
  olor: #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-content {
  margin: 0 0 0 33px;
  font-size: 14px;
  top: -4px;
  font-weight: 600;
  color: #141414;
}

.ant-timeline-item-content small {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #8c8c8c;
  margin: 0;
}

.ant-timeline-item-content p {
  color: #8c8c8c;
  font-weight: 400;
  margin-top: 10px;
  font-size: 14px;
}

.timeline-dark .ant-timeline-item-content {
  color: #fff;
}

.timeline-dark .ant-timeline-item-content p, .timeline-dark .ant-timeline-item-content small {
  color: #d9d9d9;
}

.timeline-dark .ant-timeline-item-content p {
  color: #d9d9d9;
  font-weight: 400;
  margin-top: 10px;
  font-size: 14px;
}

.timeline-dark .ant-timeline-item-head {
  background-color: #262626;
}

.svg svg {
  width: 25px;
  height: 25px;
}

#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }

  .card-profile-head {
    margin: 0 0 24px;
  }
  .tags-field .ant-form-item-control {
    line-height: 33px;
  }
  .form-tag.ant-tag {
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
  }
  .form-tag.ant-tag .anticon-close {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0,0%,100%,.3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: .75;
  }
  .form-tag.ant-tag .anticon-close:hover {
    color: #fff;
    opacity: 1;
  }
  .tags-field .ant-input {
    margin-bottom: 5px;
    margin-top: 4px;
  }

  .tags-field .ant-select {
    .ant-select-selection__choice__remove i {
      color: #fff;
      height: 16px;
      border-left: 1px solid hsla(0,0%,100%,.3);
      padding-left: 5px;
      padding-top: 2px;
      opacity: .75;

      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
    .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
      border-radius: 20px;
      padding: 2px 27px 2px 10px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 3.75px;
      margin-bottom: 3.75px;
      background-color: #3a416f;
      border: 1px solid #3a416f;
      color: #fff;
      line-height: 2;
      height: 30px;
    }
    .ant-select-selection--multiple {
      padding: 8px 10px;
    }
  }
}

#basic > div > table > tbody .ant-descriptions-row > th,
#basic > div > table > tbody .ant-descriptions-row > td {
  padding-bottom: 16px !important;
}
</style>